<script>


/**
 * Landlord GST Setup component
 */


//  import {
//   required,
// } from "vuelidate/lib/validators";


import Choices from "choices.js";
import { financial } from '@/api/misc'
import { getPMApi } from '@/api/pm'


export default {

    emits: ['confirm', 'cancel'],

    props: {
        property: {
            type: Object,
            default: () => {

            }
        }
    },
    components: {

    },

    validations: {

    },


    data() {

        return {
            pm: {},
            show_error: false,
            submitted: false,
            rent_detail_list: [],
            deleted_list: [],
            total_rent: '',
            amount: '',
            rent_type_selected: 'Rent',
        }

    },
    methods: {
        formSubmit() {
            this.$v.$touch()
            this.update_error()
            if (this.show_error) {
                return
            }

            if (this.$v.$invalid == false) {
                this.rent_detail_list.push(...this.deleted_list)
                let obj = {
                    pm_id          : this.property.pm_id,
                    rent_details   : this.rent_detail_list,
                    rent_id        : this.property.rent_id
                }
                getPMApi().update_rent_type(obj).then(res => {
                    if (res.errCode == 0) {
                        this.$emit('confirm', this.rent_detail_list)
                    } else {
                        this.show_error = true
                    }
                })

            }

        },

        onSplitRentType() {
            this.rent_detail_list.push({
                type: this.rent_type_selected,
                amount: financial(Number(this.amount), 2),
                is_new: true,
            })

            this.update_error()
        },

        removeEntry(d) {
            let item = this.rent_detail_list[d.index]
            if (!item.is_new) {
                item.is_delete = true
                this.deleted_list.push(item)
            }
            this.rent_detail_list.splice(d.index, 1)
            this.update_error()
        },


        update_error() {
            let rent = financial(Number(this.property.rent), 2)
            let amount = financial(this.rent_detail_list.reduce((a, c) => Number(a) + Number(c.amount), 0), 2)

            this.total_rent = rent - amount
            if (this.total_rent != 0) {
                this.show_error = true
            } else {
                this.show_error = false
            }


        }


    },

    created() {


    },

    mounted() {
        new Choices("#rent_type_selected", {
            removeItemButton: false,
        });

        this.total_rent = financial(Number(this.property.rent), 2)

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom02">Total Rent</label>
                            <input class="form-control" v-model="total_rent" readonly />

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom02">Split Amount</label>
                            <input class="form-control" v-model="amount" />

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">Type</label>
                            <select class="form-control" v-model="rent_type_selected" id="rent_type_selected">
                                <option value="Rent" selected>Rent</option>
                                <option value="Parking">Parking</option>
                                <option value="Parking-NR4">Parking(Tax)</option>
                                <option value="Utilities">Utilities</option>
                                <option value="Other">Other</option>
                                <option value="Owner Reserved">Owner Reserved</option>
                                <option value="Secure Deposit">Secure Deposit</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mt-4">
                        <b-button variant="primary" type="Button" @click="onSplitRentType">Add Sub Split Type</b-button>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-12">
                        <b-alert show variant="danger" v-if="show_error"> Rent mismatched current: {{property.rent }} </b-alert>
                    </div>
                </div>

                <div class="table-responsive mt-3">
                    <div class="table align-middle table-nowrap">
                        <b-table :items="rent_detail_list" :fields="['Type', 'Amount', 'Remove']" responsive="sm"
                            :per-page="10" class="table-check">
                            <template #cell(Type)="data">
                                <div class="row icon-demo-content-10">
                                    <div class="col-xl-3 col-lg-4 col-sm-6">{{ data.item.type }}</div>
                                </div>
                            </template>

                            <template #cell(Amount)="data">
                                <div class="row icon-demo-content-10">
                                    <div class="col-xl-3 col-lg-4 col-sm-6">{{ data.item.amount }}</div>
                                </div>
                            </template>

                            <template #cell(Remove)="data">
                                <div class="row icon-demo-content-10">
                                    <div class="col-xl-3 col-lg-4 col-sm-6" @click="removeEntry(data)"><i
                                            class="uil-times font-size-4"></i></div>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </div>
                <!-- end table responsive -->


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>