<script>



import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Choices from "choices.js";

import moment from 'moment'
/**
 * Rent Deposit  component
 */



export default {

    emits: ['confirm', 'cancel'],
    props:['property'],
   
    components: {
        vueDropzone: vue2Dropzone,
    },

    validations: {
        
    },

    

    data() {
        return {
            submitted : false,
            file_validate : true,
            file : {},
            rent_amount: '',
            rent_month : '',
            cheque_number : '',
            transfer_message : '',
            rent_type : 'CHEQUE',
            month_list : [],

            dropzoneOptions: {
                url: "/AwsFileUpload/s3_upload_multi",
                thumbnailWidth: 150,
                maxFilesize: 10,
            },
        }
    },

    methods: {
        formSubmit() {
           this.submitted = true
            this.$v.$touch();
            if (this.file_validate == false) {     
                this.$emit('confirm', this.file)
            }
        },


        fileUploaded(ev, resp) {
           
            if (resp[0].errCode == 200) {
                this.file_validate = false
                this.file = {
                    save_route: resp[0].response.url,
                    save_route_key: resp[0].response.key,
                    file_name :resp[0].response.filename,
                    size : resp[0].response.size,
                    type : resp[0].response.type
                }
            } else {
                this.file_validate = true
            }
        },

    },

    created() {
      
    },

    mounted() {
        this.rent_month = moment().format("YYYYMM")
        this.month_list = []
        this.property.cash_flow_list.map(c => {
            this.month_list.push({
                label : c.pm_month,
                value : c.pm_month,
                selected : this.rent_month == c.pm_month? true : false,
                c : c
            })
        })

        new Choices("#rent_month", {
            placeholderValue: "search PM",
            searchPlaceholderValue: "search by name",
            searchEnabled: false,

            }).setChoices(this.month_list);

        new Choices("#deposit_type", {
            searchEnabled: false,
        })
            



    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row mb-3">
                    <div class="col-xl-4">
                        <label for="formFileSm"  class="form-label"
                                >Rent Month</label
                            >
                        <select id="rent_month" v-model="rent_month" class="form-control">

                        </select>
                    </div>
                    <div class="col-xl-4">
                        <label for="formFileSm" class="form-label"
                                >Amount</label
                            >
                        <input type="text"  class="form-control" v-model="rent_amount" />
                    </div>
                    <div class="col-xl-4">
                        <label for="formFileSm" class="form-label"
                                >Deposit Type</label
                            >
                        <select id="deposit_type" class="form-control"  v-model="rent_type">
                            <option value="CHEQUE" selected>Cheque</option>
                            <option value="EINTERFACE" >ETransfer</option>
                        </select>
                    </div>
                   
                </div>
                <!-- end row-->


                 <div class="row" v-if="rent_type =='EINTERFACE'">
                    <div class="col-xl-4 mb-3">
                        <label for="formFileSm" class="form-label">Message</label
                            >
                        <input type="text"  class="form-control" v-model="transfer_message" />
                    </div>
                </div>
                 <!-- end row-->

                <div class="row" v-if="rent_type =='CHEQUE'">
                    <div class="col-xl-4 mb-3">
                        <label for="formFileSm" class="form-label">Cheque Number</label
                            >
                        <input type="text"  class="form-control" v-model="cheque_number" />
                    </div>
                </div>
                 <!-- end row-->
                <div class="row" v-if="rent_type =='CHEQUE'">
                    <div class="col-xl-12 mb-3">
                        <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions"   @vdropzone-success="fileUploaded">
                            <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop scaned cheque and receipt to here.</h4>
                            </div>
                        </vue-dropzone>
                    </div>

                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Confirm</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>