

<script>


/**
 * State Log TAB component
 */



export default {

    emits: ['confirm', 'cancel'],

    props: ['property'],
    components: {
        
    },

    data() {

        return {
            
        }

    },
    methods: {

    },

    created() {
      
    },

    mounted() {
      
    },


};
</script>


<template>
    <div class="row">
        <div class="card-header">
              <h5 class="card-title mb-0">State Log</h5>
            </div>
            <div class="card-body">
              <div data-simplebar style="max-height: 280px;">
                <div class="list-group list-group-flush">

                  <div class="list-group-item py-3" v-for="(item, idx) in property.state_changed_logs" :key="item.id+'_'+idx">
                    <div class="d-flex aligm-items-start">
                      <div class="flex-shrink-0 me-3">
                        <img
                          src="@/assets/images/users/avatar-2.jpg"
                          alt=""
                          class="avatar-sm rounded-circle"
                        />
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-13 mb-1 text-truncate">
                          <a href="javascript: void(0);" class="text-dark"
                            >{{item.operator.name}}</a
                          >
                          <small class="text-muted ms-1">{{ item.change_time }}</small>
                        </h5>
                        <span class="text-muted mb-0"  v-for="(str,idx) in item.change_description.split('<br>')" :key="'cgd_'+idx"><p>{{ str }}</p></span>
                      </div>

                    </div>
                  </div>
                  <!-- end list group -->


                </div>
              </div>
            </div>
            <!-- end card -->
    </div>
</template>